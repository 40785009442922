interface AppConfig {
  env: string
  version: string
  amplify: object
  sampleCoreUrl: string
}

let config: AppConfig

const getConfig = () => {
  return config
}

const setConfig = (c: AppConfig) => {
  config = c
}

export {getConfig, setConfig}
