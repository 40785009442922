import Amplify from '@aws-amplify/core'
import React from 'react'
import { setConfig } from './src/config'
import { configureGhgApi } from './src/ghgApi'
import './static/assets/styles/global.css'

export const onClientEntry = async () => {
    const res = await fetch('/config.json')
    const body = await res.json()
    setConfig(body)

    // cf. https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
    Amplify.configure(body.amplify)
    configureGhgApi(body.ghgCoreUrl)
}

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
export const wrapRootElement = ({ element }) => {
    return <>{element}</>
}
